<template>
  <b-modal id="historico-cobranca" title="Histórico de cobranças" size="lg" variant="dark" hide-footer>
    <div class="alert alert-info mb-4">
      A lista de cobrança está ordenada da ultima para a primeira.
    </div>
    <div class="text-center" v-if="!seguro || !seguro.cobrancasSeguro.length">
      <p>Não existem cobranças por aqui</p>
    </div>
    <b-table v-else :items="seguro.cobrancasSeguro" :fields="fields">
      <template #cell(criado)="criado">
        {{ formatacaoData.formatarDataLocal(criado.value) }}
      </template>
      <template #cell(statusFaturaIugu)="statusFaturaIugu">
        <span v-if="statusFaturaIugu.value == config.STATUS_FATURA_IUGU_PAGO" class="badge badge-success">Pago</span>
        <span v-if="statusFaturaIugu.value == config.STATUS_FATURA_IUGU_PENDENTE"
          class="badge badge-primary">Pendente</span>
        <span v-if="statusFaturaIugu.value == config.STATUS_FATURA_IUGU_REEMBOLSADA"
          class="badge badge-secondary">Reembolsado</span>
        <span v-if="statusFaturaIugu.value == config.STATUS_FATURA_IUGU_CANCELADA"
          class="badge badge-danger">Cancelado</span>
      </template>
      <template #cell(essorUrlApolice)="essorUrlApolice">
        <p v-if="
          essorUrlApolice.value &&
          essorUrlApolice.item.essorNumeroApolice &&
          essorUrlApolice.item.statusFaturaIugu ==
          config.STATUS_FATURA_IUGU_PAGO
        ">
          <a target="_blank" :href="essorUrlApolice.value">Visualizar apólice</a>
        </p>
        <p v-if="
          !essorUrlApolice.value &&
          !essorUrlApolice.item.essorNumeroApolice &&
          essorUrlApolice.item.statusFaturaIugu ==
          config.STATUS_FATURA_IUGU_PAGO
        ">
          Apólice em emissão na seguradora
        </p>
        <p v-if="
          essorUrlApolice.item.statusFaturaIugu !=
          config.STATUS_FATURA_IUGU_PAGO
        ">
          Não existe apolice nesta data
        </p>
        <a v-if="
          essorUrlApolice.item.statusFaturaIugu ==
          config.STATUS_FATURA_IUGU_PENDENTE
        " @click="buscarLinkFatura(essorUrlApolice.item)" href="javascript:void(0);">
          <span v-if="loading">Buscando fatura ...</span>
          <span v-else> Buscar Fatura</span>
        </a>
      </template>
      <template #cell(valor)="valor">
        {{ formatacaoMoeda3(valor.value) }}
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import config from "../../config";
import formatacaoData from "../../helpers/formatacaoData";
import captureError from "../../helpers/captureError";
import formatacaoMoeda3 from "../../helpers/formatacaoMoeda3";
import axios from "axios";
export default {
  props: ["seguro"],
  components: {},
  data() {
    return {
      formatacaoData,
      formatacaoMoeda3,
      config,
      loading: false,
      fields: [
        { key: "criado", label: "Data" },
        { key: "statusFaturaIugu", label: "Status" },
        { key: "essorUrlApolice", label: "Informações" },
        { key: "valor", label: "Valor" },
      ],
    };
  },
  mounted() { },
  methods: {
    async buscarLinkFatura(cobranca) {
      try {
        this.loading = true;
        var link = await axios.get(
          `${config.API_URLV2}/CobrancaSeguro/BuscarLinkFatura/${cobranca.idFaturaIugu}`
        );

        window.open(link.data, "_blank");

        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        this.$toast.error("não foi possivel buscar a fatura");
      }
    },
  },
};
</script>
