<template>
  <b-modal v-if="seguro" id="notificacao" title="Desativar notificações de cobrança" size="lg" variant="dark"
    :visible="showModalNotificacao" :hide-footer="true" @hide="hideModal">
    <div class="alert alert-info mb-4">
      Escolha a data de inicio e fim em que as notificações de cobrança ficarão pausadas </div>
    <div v-if="seguro.notificaoCobrancaPausada" class="alert alert-warning mb-4">
      As notificações para este seguro já estão pausadas, ele ficará sem receber notificações de
      {{ formatacaoData.formatarDataLocalsemUtc(seguro.dataInicioPausaNotificacao) }}
      até
      {{ formatacaoData.formatarDataLocalsemUtc(seguro.dataFimPausaNotificacao) }}
      <b>ao prosseguir com a operação uma nova data será atribuida</b>

    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="desativarNotificacoes()">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>Data inicio</label>
              <ValidationProvider name="Data inicio da pausa das cobranças" rules="required|data_valida"
                v-slot="{ errors, classes }">
                <input type="tel" class="form-control" v-model="dataInicioPausa" :class="classes" v-mask="'##/##/####'" />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label>Data fim</label>
              <ValidationProvider name="Data fim da pausa das cobranças" rules="required|data_valida"
                v-slot="{ errors, classes }">
                <input type="tel" class="form-control" v-model="dataFimPausa" :class="classes" v-mask="'##/##/####'" />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <hr class="mt-3 mb-3" />
        <button class="btn btn-success btn-block" type="submit" :disabled="loading || invalid">
          <span v-if="loading">Desativando notificações...</span>
          <span v-else>Desativar notificações</span>
        </button>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import config from "../../config";
import axios from "axios";
import formatacaoData from "../../helpers/formatacaoData";
import captureError from "../../helpers/captureError";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import moment from "moment";
export default {
  props: ["seguro", "showModalNotificacao"],
  components: {},
  data() {
    return {
      formatacaoData,
      formatacaoMoeda,
      config,
      loading: false,
      dataInicioPausa: undefined,
      dataFimPausa: undefined,
      modificou: false,
    };
  },

  watch: {

  },
  methods: {
    hideModal(arg, data) {
      this.$emit("hide", data || { modificou: false });
      this.dataInicioPausa = undefined;
      this.dataFimPausa = undefined;
    },

    async desativarNotificacoes() {
      try {
        this.loading = true;

        var result = await Swal.fire({
          text: "Deseja realmente desativar as notificações de cobrança? ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (result?.isConfirmed == false || result?.dismiss == "cancel") {
          this.loading = false;
          return;
        }

        var data = {
          idSeguro: this.seguro.id,
          dataInicioPausa: moment(this.dataInicioPausa, "DD/MM/YYYY").format("YYYY-MM-DD"),
          dataFimPausa: moment(this.dataFimPausa, "DD/MM/YYYY").format("YYYY-MM-DD"),
        };

        await axios.post(
          `${config.API_URLV2}/seguro/pausarnotificacao/`,
          data
        );

        this.loading = false;

        this.hideModal(undefined, { modificou: true });
        this.$toast.success("Notificações desativadas", {
          duration: 5000,
        });
      } catch (error) {
        this.loading = false;
        captureError(error);
        this.$toast.error("não foi possivel desativar as notificações de cobrança");
      }
    },
  },
};
</script>
