<template>
  <div>
    <p v-if="item.inspecStatus == null">Não informado</p>

    <p v-if="item.inspecStatus == config.STATUS_INSPEC_AGUARDANDO">
      Cliente ainda não fez a vistoria
      <br />
      <a
        v-if="item.situacaoSeguro == config.SITUACAO_ENVIADA"
        href="javascript:void(0);"
        @click="copy(item.urlInspecKakau)"
      >
        copiar link inspec</a
      >
    </p>

    <input
      type="text"
      class="d-none"
      :value="item.urlInspecKakau"
      :id="item.urlInspecKakau"
    />

    <p v-if="item.inspecStatus == config.STATUS_INSPEC_AVALIANDO">
      Inspec recebeu mas ainda não realizou a vistoria
    </p>
    <p v-if="item.inspecStatus == config.STATUS_INSPEC_APROVADO">
      Aprovado pela inspec
    </p>

    <p v-if="item.motivoInspec">Motivo Inspec: {{ item.motivoInspec }}</p>
    <p v-if="item.motivoIspecInterno">
      <b class="text-danger">Motivo Inspec Interno</b>:
      {{ item.motivoIspecInterno }}
    </p>

    <p v-if="item.inspecStatus == config.STATUS_INSPEC_VALOR_ALTERADO">
      <b>Aprovado pela inspec com valor alterado ao informado pelo cliente</b>:
      Valor Informado: {{ formatacaoMoeda(item.valorBike) }} | Valor Aprovado:
      {{ formatacaoMoeda(item.valorBikeAprovado) }}
    </p>
    <p v-if="item.inspecStatus == config.STATUS_INSPEC_DADOS_INCOMPLETOS">
      {{ item.mensagemStatusInspec }}
    </p>
  </div>
</template>

<script>
import config from "../../config";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
export default {
  props: ["item"],
  components: {},
  data() {
    return { config, formatacaoMoeda };
  },
  created() {},
  methods: {
    copy(id) {
      var copyText = document.getElementById(id);

      copyText.select();
      copyText.setSelectionRange(0, 99999);

      navigator.clipboard.writeText(copyText.value);

      this.$toast.success("Copiado");
    },
  },
};
</script>
