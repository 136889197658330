<template>
  <b-modal
    :visible="showModal"
    title="Informações seguro"
    :hide-footer="true"
    @hide="hideModal"
    size="lg"
  >
    <div class="row" v-if="seguro">
      <div class="col-lg-12 order-lg-2">
        <div>
          <table class="table text-center">
            <thead>
              <tr>
                <th>Modalidade</th>
                <th>Ano Bike</th>
                <th>Nome Plano</th>
                <th>Valor Bike</th>
                <th>Valor seguro</th>
                <th v-if="seguro.cnpjLoja != null">Cnpj Parceiro</th>
                <th v-if="dataSeguro != null">Data Aprovado</th>
                <th v-if="seguro.inspecStatusDataModificado != null">
                  Aprovado Inspec
                </th>
                <th v-if="seguro.idSubscriptionKakau != null">Id Kakau</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {{ seguro.modalidadeBike }}
                </td>
                <td>
                  {{ seguro.anoBike }}
                </td>
                <td>
                  {{ seguro.planoSeguro }}
                </td>
                <td v-if="seguro.valorBikeAprovado != null">
                  {{ formatacaoMoeda(seguro.valorBikeAprovado) }}
                </td>
                <td v-if="seguro.valorBikeAprovado == null">
                  {{ formatacaoMoeda(seguro.valorBike) }}
                </td>
                <td v-if="seguro.valorPlanoSeguroAprovado != null">
                  {{ formatacaoMoeda(seguro.valorPlanoSeguroAprovado) }}
                </td>
                <td v-if="seguro.valorPlanoSeguroAprovado == null">
                  {{ formatacaoMoeda(seguro.valorPlanoSeguro) }}
                </td>

                <td v-if="seguro.cnpjLoja != null">
                  {{ helper.formatacaoCpf(seguro.cnpjLoja) }}
                </td>

                <td v-if="dataSeguro != null">
                  {{ formatacaoData.formatarDataHoraLocal(dataSeguro) }}
                </td>
                <td v-if="seguro.inspecStatusDataModificado != null">
                  {{
                    formatacaoData.formatarDataHoraLocal(
                      seguro.inspecStatusDataModificado
                    )
                  }}
                </td>
                <td v-if="seguro.idSubscriptionKakau != null">
                  {{ seguro.idSubscriptionKakau }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import config from "../../config";
import formatacaoData from "../../helpers/formatacaoData";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import helper from "../../helpers/helper";
export default {
  props: ["showModal", "seguro", "dataSeguro"],
  data() {
    return {
      formatacaoData,
      formatacaoMoeda,
      config,
      loading: false,
      helper
    };
  },
  
  methods: {
      hideModal() { 
      this.$emit("hide");
    },
  },
};
</script>
