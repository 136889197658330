<template>
  <b-modal
    id="desconto"
    title="Desconto"
    size="lg"
    variant="dark"
    :visible="showModalDesconto"
    :hide-footer="true"
    @hide="hideModal"
  >
      <div v-if="seguro!= undefined && seguro.cupom" class="alert alert-warning mb-4">
     Esse seguro já possui o cupom: {{seguro.cupom}}
    </div>
    <div v-if="!dataDesconto" class="alert alert-info mb-4">
      Aplique o valor do desconto em percentual
    </div>
    <div v-if="dataDesconto" class="alert alert-warning mb-4">
      Aviso! Essa assinatura já tem um desconto aplicado na data:
      {{ formatacaoData.formatarDataHoraLocal(seguro.dataDesconto) }}. Valor
      antes do desconto: {{ formatacaoMoeda3(seguro.valorPlanoAnterior) }}
      <div class="mt-2">
        <button
          class="btn btn-danger btn-block"
          @click.prevent="cancelarDesconto()"
          :disabled="loading "
        >
          <span v-if="loading">Cancelando o desconto...</span>
          <span v-else>Cancelar o desconto</span>
        </button>
      </div>
    </div>
    <div v-if="!dataDesconto" class="mb-3">
        <b-form-checkbox id="checkbox-1" v-model="status" name="checkbox-1">
         <strong>Renovar sem desconto</strong>
        </b-form-checkbox>
    </div>
    <div v-if="!status">
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(aplicarDesconto)">
        <div class="row">
            <div class="col-6" v-if="!dataDesconto"> 
              <div class="form-group">
                <label>Valor do desconto (%)</label>
                <ValidationProvider
                  name="valor do desconto"
                  rules="required"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="number"
                    class="form-control"
                    v-model="valorDesconto"
                    :class="classes"
                    placeholder="Valor do desconto"
                  />
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-6" v-if="!dataDesconto">
              <div class="form-group">
                <label>Data fim do desconto</label>
                <ValidationProvider
                  name="Data fim do desconto"
                  rules="required|data_fim_desconto"
                  v-slot="{ errors, classes }"
                >
                  <input
                    type="tel"
                    class="form-control"
                    v-model="dataFimDesconto"
                    :class="classes"
                    v-mask="'##/##/####'"
                  />
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          
          <div class="col-6">
            <div class="form-group">
              <label>Valor final do seguro</label>
              <h2>
                {{
                  valorDesconto
                    ? formatacaoMoeda3(
                        valorSeguro - (valorSeguro * valorDesconto) / 100
                      )
                    : formatacaoMoeda3(valorSeguro)
                }}
              </h2>
            </div>
          </div>
        </div>

        <hr class="mt-3 mb-3" />
        <button
          v-show="!dataDesconto"
          class="btn btn-success btn-block"
          type="submit"
          :disabled="loading || invalid"
        >
          <span v-if="loading">Aplicando desconto...</span>
          <span v-else>Aplicar desconto</span>
        </button>
      </form>
    </ValidationObserver></div>
    <div v-else>
        <button
          class="btn btn-success btn-block"
         @click.prevent="renovarSeguro()"
          :disabled="loading"
        >
          <span v-if="loading">Aplicando desconto...</span>
          <span v-else>Aplicar desconto</span>
        </button>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import auth from "../../auth";
import config from "../../config";
import axios from "axios";
import formatacaoData from "../../helpers/formatacaoData";
import captureError from "../../helpers/captureError";
import formatacaoMoeda3 from "../../helpers/formatacaoMoeda3";
import moment from "moment";
export default {
  props: ["seguro", "showModalDesconto"],
  components: {},
  data() {
    return {
      formatacaoData,
      formatacaoMoeda3,
      config,
      loading: false,
      valorDesconto: undefined,
      dataFimDesconto: undefined,
      valorSeguro: 0,
      modificou: false,
      dataDesconto:null,
      status:false

    };
  },
 
  watch: {
    showModalDesconto: function (newValue, oldValue) {
      if (newValue != oldValue && newValue ){
        this.definirValorSeguro();
        this.dataDesconto=this.seguro?.dataDesconto;
      }},
      valorDesconto:function (newValue, oldValue) {
         if (newValue != oldValue && typeof newValue === 'string'){
           this.valorDesconto =newValue.replace(/[^0-9]/g, '');
         }
      if (newValue != oldValue && newValue>100){
      return this.valorDesconto=100;
      }
    },
  },
  methods: {
    hideModal(arg, data) {
      this.$emit("hide", data || { modificou: false });
      this.dataFimDesconto=undefined;
      this.valorDesconto=undefined;
    },
    definirValorSeguro() {
      return (this.valorSeguro =
        this.seguro.valorPlanoSeguroAprovado ?? this.seguro.valorPlanoSeguro);
    },
    async aplicarDesconto() {
      try {
        this.loading = true;

        var result = await Swal.fire({
          text: "Deseja realmente aplicar o desconto?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (result?.isConfirmed == false || result?.dismiss == "cancel") {
          this.loading = false;
          return;
        }

        var userInfo = await auth.getUserInfo();

        var data = {
          idAssinaturaIugu: this.seguro.cobrancasSeguro[0]?.idAssinaturaIugu,
          idSeguro: this.seguro.id,
          valorDesconto: this.valorDesconto,
          idUsuarioAdm: userInfo.id,
          dataFimDesconto: moment(this.dataFimDesconto, "DD/MM/YYYY").format("YYYY-MM-DD"),
        };

        await axios.post(`${config.API_URLV2}/CobrancaSeguro/Desconto/`, data);

        this.loading = false;
        this.valorDesconto = undefined;

        this.hideModal(undefined, { modificou: true });
        this.$toast.success("Desconto aplicado", {
          duration: 3000,
        });
      } catch (error) {
        this.loading = false;
        captureError(error);
        this.$toast.error("não foi possivel aplicar o desconto");
      }
    },

    async renovarSeguro() {
      try {
        this.loading = true;

        var result = await Swal.fire({
          text: "Deseja realmente renovar sem o desconto?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (result?.isConfirmed == false || result?.dismiss == "cancel") {
          this.loading = false;
          return;
        }

        var userInfo = await auth.getUserInfo();

        var data = {
          idAssinaturaIugu: this.seguro.cobrancasSeguro[0]?.idAssinaturaIugu,
          idSeguro: this.seguro.id,
          valorDesconto:0,
          idUsuarioAdm: userInfo.id,
        };

        await axios.post(`${config.API_URLV2}/CobrancaSeguro/Desconto/`, data);

        this.loading = false;
        this.valorDesconto = undefined;

        this.hideModal(undefined, { modificou: true });
        this.$toast.success("Desconto aplicado", {
          duration: 3000,
        });
      } catch (error) {
        this.loading = false;
        captureError(error);
        this.$toast.error("não foi possivel aplicar o desconto");
      }
    },
    async cancelarDesconto() {
      try {
        this.loading = true;

        var result = await Swal.fire({
          text: "Deseja realmente cancelar o desconto? ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (result?.isConfirmed == false || result?.dismiss == "cancel") {
          this.loading = false;
          return;
        }

        var userInfo = await auth.getUserInfo();

        var data = {
          idAssinaturaIugu: this.seguro.cobrancasSeguro[0]?.idAssinaturaIugu,
          idSeguro: this.seguro.id,
          valorDesconto: this.valorDesconto,
          idUsuarioAdm: userInfo.id,
        };

        await axios.post(
          `${config.API_URLV2}/CobrancaSeguro/CancelarDescontoIugu/`,
          data
        );

        this.loading = false;
        this.valorDesconto = undefined;

        this.hideModal(undefined, { modificou: true });
        this.$toast.success("Desconto cancelado", {
          duration: 3000,
        });
      } catch (error) {
        this.loading = false;
        captureError(error);
        this.$toast.error("não foi possivel cancelar o desconto");
      }
    },
  },
};
</script>
