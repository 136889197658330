<template>
  <b-modal id="email" title="Liberar e-mail para cotação" size="md" variant="dark" :visible="show"
    :hide-footer="true" @hide="hideModal">

    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="salvar">
        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="form-group">
              <label>E-mail</label>
              <ValidationProvider name="email" rules="required|email" v-slot="{ errors, classes }">
                <input v-model="email" type="e-mail" class="form-control" :class="classes"
                  placeholder="Email do cliente" />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

        </div>

        <hr class="mt-3 mb-3" />
        <button class="btn btn-success btn-block" type="submit" :disabled="loading || invalid">
          <span v-if="loading">Liberando o e-mail...</span>
          <span v-else>Liberar e-mail para cotação</span>
        </button>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import config from "../../config";
import axios from "axios";
export default {
  props: ["show"],
  components: {},
  data() {
    return {
      config,
      loading: false,
      email: undefined,
    };
  },

  watch: {

  },
  methods: {
    hideModal() {
      this.$emit("hide");
      this.email = undefined;
    },

    async salvar() {
      try {
        this.loading = true;

        var result = await Swal.fire({
          text: "Deseja realmente liberar o e-mail para realizar a cotação? ",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#28a745",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        });

        if (result?.isConfirmed == false || result?.dismiss == "cancel") {
          this.loading = false;
          return;
        }

        var data = { email: this.email };

        await axios.post(
          `${config.API_URLV2}/email`,
          data
        );

        this.loading = false;

        this.hideModal();
        this.$toast.success("E-mail liberado", {
          duration: 5000,
        });
      } catch (error) {
        this.loading = false;
        this.$toast.error(error?.response?.data?.error || "não foi possivel liberar o e-mail fornecido");
      }
    },
  },
};
</script>
