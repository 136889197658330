var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"historico-cobranca","title":"Histórico de cobranças","size":"lg","variant":"dark","hide-footer":""}},[_c('div',{staticClass:"alert alert-info mb-4"},[_vm._v(" A lista de cobrança está ordenada da ultima para a primeira. ")]),(!_vm.seguro || !_vm.seguro.cobrancasSeguro.length)?_c('div',{staticClass:"text-center"},[_c('p',[_vm._v("Não existem cobranças por aqui")])]):_c('b-table',{attrs:{"items":_vm.seguro.cobrancasSeguro,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(criado)",fn:function(criado){return [_vm._v(" "+_vm._s(_vm.formatacaoData.formatarDataLocal(criado.value))+" ")]}},{key:"cell(statusFaturaIugu)",fn:function(statusFaturaIugu){return [(statusFaturaIugu.value == _vm.config.STATUS_FATURA_IUGU_PAGO)?_c('span',{staticClass:"badge badge-success"},[_vm._v("Pago")]):_vm._e(),(statusFaturaIugu.value == _vm.config.STATUS_FATURA_IUGU_PENDENTE)?_c('span',{staticClass:"badge badge-primary"},[_vm._v("Pendente")]):_vm._e(),(statusFaturaIugu.value == _vm.config.STATUS_FATURA_IUGU_REEMBOLSADA)?_c('span',{staticClass:"badge badge-secondary"},[_vm._v("Reembolsado")]):_vm._e(),(statusFaturaIugu.value == _vm.config.STATUS_FATURA_IUGU_CANCELADA)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Cancelado")]):_vm._e()]}},{key:"cell(essorUrlApolice)",fn:function(essorUrlApolice){return [(
        essorUrlApolice.value &&
        essorUrlApolice.item.essorNumeroApolice &&
        essorUrlApolice.item.statusFaturaIugu ==
        _vm.config.STATUS_FATURA_IUGU_PAGO
      )?_c('p',[_c('a',{attrs:{"target":"_blank","href":essorUrlApolice.value}},[_vm._v("Visualizar apólice")])]):_vm._e(),(
        !essorUrlApolice.value &&
        !essorUrlApolice.item.essorNumeroApolice &&
        essorUrlApolice.item.statusFaturaIugu ==
        _vm.config.STATUS_FATURA_IUGU_PAGO
      )?_c('p',[_vm._v(" Apólice em emissão na seguradora ")]):_vm._e(),(
        essorUrlApolice.item.statusFaturaIugu !=
        _vm.config.STATUS_FATURA_IUGU_PAGO
      )?_c('p',[_vm._v(" Não existe apolice nesta data ")]):_vm._e(),(
        essorUrlApolice.item.statusFaturaIugu ==
        _vm.config.STATUS_FATURA_IUGU_PENDENTE
      )?_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.buscarLinkFatura(essorUrlApolice.item)}}},[(_vm.loading)?_c('span',[_vm._v("Buscando fatura ...")]):_c('span',[_vm._v(" Buscar Fatura")])]):_vm._e()]}},{key:"cell(valor)",fn:function(valor){return [_vm._v(" "+_vm._s(_vm.formatacaoMoeda3(valor.value))+" ")]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }