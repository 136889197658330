<template>
  <b-modal
    :visible="showModalAlterar"
    title="Alterar dados seguro"
    :hide-footer="true"
    @hide="hideModal"
    size="lg"
  >
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form class="mt-2" @submit.prevent="handleSubmit(salvar)">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="nome"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>Nome</label>
                <input
                  v-model="seguro.nomeCliente"
                  type="text"
                  class="form-control"
                  :class="classes"
                  placeholder="Nome do cliente"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="Rg"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>Rg</label>
                <input
                  v-model="seguro.rgCliente"
                  type="text"
                  class="form-control"
                  :class="classes"
                  placeholder="Rg do cliente"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="email"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>Email</label>
                <input
                  v-model="seguro.emailCliente"
                  type="e-mail"
                  class="form-control"
                  :class="classes"
                  placeholder="Email do cliente"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="nr serie"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>Número de série da bike</label>
                <input
                  v-model="seguro.numeroSerieBike"
                  type="text"
                  class="form-control"
                  :class="classes"
                  placeholder="Nr de série da bike"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="modelo bike"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>Modelo da bike</label>
                <input
                  v-model="seguro.modeloBike"
                  type="text"
                  class="form-control"
                  :class="classes"
                  placeholder="Modelo da bike"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="marca bike"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>Marca da bike</label>
                <input
                  v-model="seguro.marcaBike"
                  type="text"
                  class="form-control"
                  :class="classes"
                  placeholder="Marca da bike"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>UF</label>
              <ValidationProvider
                name="estado"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <select
                  class="form-control"
                  v-model="seguro.ufCliente"
                  :class="classes"
                  data-test-id="input-estado"
                >
                  <option :value="undefined">Selecione...</option>
                  <option>AC</option>
                  <option>AL</option>
                  <option>AM</option>
                  <option>AP</option>
                  <option>BA</option>
                  <option>CE</option>
                  <option>DF</option>
                  <option>ES</option>
                  <option>GO</option>
                  <option>MA</option>
                  <option>MG</option>
                  <option>MS</option>
                  <option>MT</option>
                  <option>PA</option>
                  <option>PB</option>
                  <option>PE</option>
                  <option>PI</option>
                  <option>PR</option>
                  <option>RJ</option>
                  <option>RN</option>
                  <option>RO</option>
                  <option>RR</option>
                  <option>RS</option>
                  <option>SC</option>
                  <option>SE</option>
                  <option>SP</option>
                  <option>TO</option>
                </select>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>Cidade</label>
              <ValidationProvider
                name="cidade"
                rules="required|min:3"
                v-slot="{ errors, classes }"
              >
                <input
                  type="text"
                  class="form-control"
                  :class="classes"
                  v-model="seguro.cidadeCliente"
                  autocomplete="nope"
                  maxlength="50"
                  data-test-id="input-cidade"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="ano da bike"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>Ano da bike</label>
                <input
                  v-model="seguro.anoBike"
                  type="text"
                  class="form-control"
                  :class="classes"
                  placeholder="Ano da bike"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="CEP"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>CEP</label>
                <input
                  v-model="seguro.cepCliente"
                  type="text"
                  class="form-control"
                  :class="classes"
                  placeholder="CEP"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>Nascimento</label>
              <ValidationProvider
                name="nascimento"
                rules="required|data_nascimento|data_maior_idade"
                v-slot="{ errors, classes }"
              >
                <input
                  type="tel"
                  class="form-control"
                  :class="classes"
                  v-model="nascimento"
                  v-mask="'##/##/####'"
                  autocomplete="nope"
                  data-test-id="input-data-nascimento"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="endereço"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>Endereço</label>
                <input
                  v-model="seguro.enderecoCliente"
                  type="text"
                  class="form-control"
                  :class="classes"
                  placeholder="Endereço"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="bairro"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>Bairro</label>
                <input
                  v-model="seguro.bairroEnderecoCliente"
                  type="text"
                  class="form-control"
                  :class="classes"
                  placeholder="Bairro"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="complemento"
                v-slot="{ errors, classes }"
              >
                <label>Complemento</label>
                <input
                  v-model="seguro.complementoEnderecoCliente"
                  type="text"
                  class="form-control"
                  :class="classes"
                  placeholder="Complemento"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="numero"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <label>Número</label>
                <input
                  v-model="seguro.numeroEnderecoCliente"
                  type="text"
                  class="form-control"
                  :class="classes"
                  placeholder="Número"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <ValidationProvider
                name="telefone"
                rules="required|min:14"
                v-slot="{ errors, classes }"
              >
                <label>Telefone</label>
                <input
                  type="tel"
                  class="form-control"
                  :class="classes"
                  v-model="seguro.telefoneCliente"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                  data-test-id="input-telefone"
                  autocomplete="nope"
                />
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <button
          type="submit"
          class="btn btn-success btn-block"
          :disabled="invalid || loading"
        >
          <span v-if="!loading">Salvar alterações</span>
          <span v-else>Por favor aguarde...</span>
        </button>
      </form>
    </ValidationObserver>
  </b-modal>
</template>

<script>
import config from "../../config";
import captureError from "../../helpers/captureError";
import axios from "axios";
import moment from "moment";
export default {
  props: ["seguro", "showModalAlterar", "nascimento"],
  components: {},
  data() {
    return {
      config,
      loading: false,
    };
  },
  methods: {
    hideModal() {
      this.$emit("hide");
    },
    async salvar() {
      try {
        this.seguro.urlApolice = null;
        this.loading = true;
        await axios.put(`${config.API_URLV2}/Seguro/`, this.seguro);

        this.$toast.success("seguro atualizado com sucesso");

        this.loading = false;
      } catch (error) {
        this.loading = false;
        captureError(error);
        this.$toast.error("não foi possivel alterar o seguro");
      }
    },
  },
  watch: {
    nascimento: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.seguro.nascimentoCliente = moment(
          this.nascimento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
      }
    },
  },
};
</script>
